import React , {useState} from 'react';
import './setupStyle.css'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import SubmitButton from '../buttonComponent/Button'
import Backdrop from '@material-ui/core/Backdrop';
import { useAuth } from '../../context/contextProvider';
import { setLocation, createDocumentsForCollection } from '../../firebase/insertData';

const buttonStyle = {height : "50px",background : "#FBCE07", color: " #000000",fontSize: "25px" , float : 'right',fontFamily: "Poppins",
}
const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign : "center",
      padding : '8px'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      overflow : "auto",
      maxHeight : "100%",
      border: '2px solid #000',
      position: 'relative',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      "& h2": {
          color : "#DD1D21 ",
      },
      "& h1" : {
          color : "#606060",
          fontSize : "2.125rem",
          margin : "10px"
      },
      "& div" : {
          margin : "10px 10px"
      }
    },
  }));

function Setup() {
  const auth = useAuth();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [StationInfo, setStationInfo] = useState({
    Station_Name : '',
    Station_Address : '',
    Station_City : '',
    Station_MobileNumber : '',
    Station_Latitude : '',
    Station_Longitude : '',
  })
  const nameChange = (e) => {
    setStationInfo({...StationInfo,Station_Name : e.target.value})
  }
   const addressChange = (e) => {
    setStationInfo({...StationInfo,Station_Address : e.target.value})
  }
   const cityChange = (e) => {
    setStationInfo({...StationInfo,Station_City : e.target.value})
  }
   const mobileChange = (e) => {
    setStationInfo({...StationInfo,Station_MobileNumber : e.target.value})
  }
  const latitudeChange = (e) => {
    setStationInfo({...StationInfo,Station_Latitude : e.target.value})
  }
  const longitudeChange = (e) => {
    setStationInfo({...StationInfo,Station_Longitude : e.target.value})
  }
  const HandleCheck = async () => {
      if (
        StationInfo.Station_MobileNumber === '' || 
        StationInfo.Station_City === '' || 
        StationInfo.Station_Address === ''||
        StationInfo.Station_Name === '' || 
        StationInfo.Station_Latitude === ''||
        StationInfo.Station_Longitude === ''
      )
        {
        }
      else
        {
            setLocation({lat : parseFloat(StationInfo.Station_Latitude), lng : parseFloat(StationInfo.Station_Longitude)})
            localStorage.setItem('vivoInfo', JSON.stringify(StationInfo))
            setOpen(false)
            auth.setStationInfo(StationInfo)
            createDocumentsForCollection(
              "stations", 
              {
                adresse: StationInfo.Station_Address,
                ville: StationInfo.Station_City,
                telephone: StationInfo.Station_MobileNumber,
                nom: StationInfo.Station_Name
              }
              );
        }
    }
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
              <h1>Veuillez compléter les informations ci-dessous</h1>
                <div className='SetupForm'>
                    <input type="text" placeholder="Nom de la station" onChange={nameChange}/>
                    <input type="text" placeholder="Adresse" onChange={addressChange}/>
                    <input type='text' placeholder='Ville' onChange={cityChange}/>
                    <input type='text' placeholder='Téléphone' onChange={mobileChange}/>
                    <input type='text' placeholder='Latitude' onChange={latitudeChange}/>
                    <input type='text' placeholder='Longitude' onChange={longitudeChange}/>
                </div>
                <SubmitButton name='Suivant' Theme={buttonStyle} verify={() => HandleCheck()}/>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
export default Setup