import React from 'react'
import Button from '@material-ui/core/Button';



export default function indexButton(props) {
    return (
            <Button variant="contained" style={props.Theme} onClick={props.verify}>
                {props.name}
            </Button>
    )
}
