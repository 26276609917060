import {authCheck} from './firebase'
import 'firebase/firestore';
 async function InserData(info,stationInfo) {
   let res = await authCheck.firestore().collection('clients').doc()
   const data = {...info, ...stationInfo};
   res.set(data)
}
export {InserData}
async function setLocation(info) {
  let res = await authCheck.firestore().collection('maps').doc()
  res.set(info)
}
export {setLocation}
 
const createDocumentsForCollection =  async (collectionKey, data) =>{
  const collectionRef = authCheck.firestore().collection(collectionKey);
  const docRef = collectionRef.doc();
  const snapShot = await docRef.get();
  const createdAt = new Date();
  try{
   await docRef.set({
     id: snapShot.id,
     createdAt,
     ...data
   })

  }catch(e){
    console.log(e.message);
  }

}
export {createDocumentsForCollection}
