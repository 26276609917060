import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyDRpsN2GAZvN_QMHTe5HudF9FTWvdz1FTM",
    authDomain: "vivo-5ba49.firebaseapp.com",
    projectId: "vivo-5ba49",
    storageBucket: "vivo-5ba49.appspot.com",
    messagingSenderId: "596914657048",
    appId: "1:596914657048:web:ada4db6fd11853ea00b06d"
};
// Initialize Firebase
firebase.initializeApp(config);

export const authCheck = firebase;