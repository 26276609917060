import React , {useState, createContext, useEffect}from 'react'
import Setup from '../components/setupComponent/setup'
import {getGift} from '../firebase/getProducts'
export const AuthContext = createContext()
export const useAuth = () => (
    React.useContext(AuthContext)
)
const AuthProvider = ({children}) => {
    const [status, setstatus] = useState(0)
    const [globalinfo, setglobalinfo] = useState({
        mobileNumber : '',
        productName : '',
        clientStatus : 'old',
        giftName : '',
        giftUrl : '',
        giftDescription : '',
        oilsKm : '',
    })
    const [stationInfo, setStationInfo] = useState({
        Station_Name : '',
        Station_Address : '',
        Station_City : '',
        Station_MobileNumber : '',
        Station_Latitude : '',
        Station_Longitude : '',
    })
    useEffect(() => {
        const data = localStorage.getItem('vivoInfo')
        setStationInfo(JSON.parse(data))
        getGift().then(res => {
            setglobalinfo({...globalinfo, giftName : res[0]?.name, giftUrl : res[0]?.url, giftDescription : res[0]?.description})
          })
    }, [])
    return (
        <AuthContext.Provider value={{status, setstatus, globalinfo, setglobalinfo, setStationInfo, stationInfo}}>
            {children}
            {
                !stationInfo?.Station_City ? <Setup /> : ''
            }
        </AuthContext.Provider>
    )
}
export default AuthProvider