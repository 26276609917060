import {authCheck} from './firebase'
import 'firebase/firestore';
 async function GetProducts() {
   const db = authCheck.firestore()
   const getData = await db.collection("huiles").orderBy("km", "desc").get()
   return getData.docs.map(doc => doc.data());
}
export {GetProducts}

async function getGift() {
  const db = authCheck.firestore()
  const getData = await db.collection("cadeaux").get()
  return getData.docs.map(doc => doc.data());
}
export {getGift}